import qs from 'qs'
import { navigateTo } from 'nuxt/app'
import type { Route } from '../types/Api/Query/Route'
import type { ShowResponse } from '../types/Api/Response/ShowResponse'

export class ApiService {
    constructor(fetch: unknown, config: object, access_token: object, notifyStore: unknown, i18n: unknown, userStore: unknown) {
        this.i18n = i18n
        this.notifyStore = notifyStore
        this.userStore = userStore
        this.access_token = access_token
        this.$fetch = fetch
        this.$config = config
    }

    getQuery(route: Route) {
        if (!route.query) {
            return ''
        }
        const query = route.query
        Object.keys(query).forEach((f) => {
            if (!f.includes('filter[')) {
                query[`filter[${f}]`] = query[f]
                delete query[f]
            }
        })
        const queryString = qs.stringify(route.query, { encode: false, arrayFormat: 'comma' })
        return queryString ? route.url.includes('?') ? `&${queryString}` : `?${queryString}` : ''
    }

    private async sendRequest(route: Route, url: string, method: string, throwError = false): Promise<any> {
        try {
            const access_token_updated = this.userStore?.access_token || this.access_token?.value

            const headers: object = access_token_updated
                ? {
                        ...route.headers,
                        Accept: 'application/json',
                        Authorization: `Bearer ${access_token_updated}`,
                    }
                : {}
            const content = method === 'GET' ? { method, headers } : { method, headers, body: route.body }

            // noinspection UnnecessaryLocalVariableJS
            const res = await this.$fetch(url, content)
            return res
        }
        catch (e) {
            if (throwError) {
                throw e
            }
            else {
                if (e?.response?.status === 401) {
                    this.access_token = null
                    this.userStore.clearUser().then()
                    navigateTo('/connexion')
                }
                else if (e?.response?.status === 503) { // MAINTENANCE
                    navigateTo('/')
                }
                else {
                    this.notifyStore.showMessage({
                        title: 'Erreur lors de la requête',
                        text: e?.response?._data?.message,
                        color: 'error',
                        icon: 'mdi-alert-circle',
                        timer: 800,
                    })
                }

                return { ...e?.data, e } // FIXME devrait retourner une promise
                // TODO il faudrait utiliser le code suivant et verifier si on ne casse pas des choses ailleurs
                // return new Promise((resolve, reject) => {
                //     reject(e)
                // })
            }
        }
    }

    public async getListData(route: Route) {
        const url = `${this.$config.public.apiBase}/${route.url}${this.getQuery(route)}`

        const paginateQuery = {}
        if (route.pagination) {
            paginateQuery.page = {
                number: route.pagination.number,
                size: route.pagination.size,
            }
        }
        if (route.sort && route.sort.key) {
            paginateQuery.sort = `${route.sort.order === 'desc' ? '-' : ''}${route.sort.key}`
        }
        const paginateQueryString = qs.stringify(paginateQuery, { encode: false, arrayFormat: 'comma' })
        const fullUrl = url + (url.includes('?') ? `&${paginateQueryString}` : `?${paginateQueryString}`)

        // let url = `${this.$config.public.apiBase}/${route.url}${this.getQuery(route)}`
        // url += this.getQuery(route) ? '&' : '?'
        // url += `${route.pagination ? `page[number]=${route.pagination.number}&page[size]=${route.pagination.size}` : ''}`
        // url += route.pagination && route.sort?.key ? '&' : ''
        // url += `${route.sort && route.sort.key ? `sort=${route.sort.order === "desc" ? '-' : ''}${route.sort.key}` : ''}`

        return this.sendRequest(route, fullUrl, 'GET')
    }

    async getData(route: Route): Promise<ShowResponse<any> | Blob> {
        const routeUrl = route.url.startsWith('/') ? route.url.slice(1) : route.url
        let url = new URL(`${this.$config.public.apiBase}/${routeUrl}`).toString()
        if (route.query) {
            url += this.getQuery(route)
        }
        return this.sendRequest(route, url, 'GET')
    }

    async deleteData(route: Route): Promise<any> {
        const url = `${this.$config.public.apiBase}/${route.url}`
        return this.sendRequest(route, url, 'DELETE')
    }

    async setRequest(route: Route, throwError: boolean = false): Promise<any> {
        const url = `${this.$config.public.apiBase}/${route.url}${this.getQuery(route)}`
        const res = await this.sendRequest(route, url, route.method, throwError)

        if (res && res.e instanceof Error) { // will not be necessary if sendRequest use reject instead of return success when query fail
            return // dont show success message on error
        }

        if (route.translation && res) {
            if (this.i18n.t(`${route.translation}.toaster.${route.method}.success.title`) || this.i18n.t(`${route.translation}.toaster.${route.method}.success.text`)) {
                this.notifyStore.showMessage({
                    title: this.i18n.t(`${route.translation}.toaster.${route.method}.success.title`),
                    text: this.i18n.t(`${route.translation}.toaster.${route.method}.success.text`, res.data ? { ...res.data } : { ...res }),
                    color: 'success',
                    icon: 'mdi-check-circle',
                })
            }
        }
        return res
    }

    async triggerDownload(url: string, filename: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.getData({
                url,
            }).then((blob) => {
                if (!(blob instanceof Blob)) {
                    if (typeof blob === 'object') {
                        blob = JSON.stringify(blob)
                    }
                }

                blob = new Blob([blob])
                const dataUrl = URL.createObjectURL(blob)
                const a = document.createElement('a')
                document.body.appendChild(a)
                a.href = dataUrl
                a.download = filename
                a.click()
                window.URL.revokeObjectURL(this.dataUrl)
                a.remove()
                if (blob) {
                    resolve()
                }
            })
        })
    }

    async saveDocuments(documentsTyped: object, requestName: string, k: string, translationName: string): Promise<void> {
        const types = Object.keys(documentsTyped)
        types.forEach((type) => {
            documentsTyped[type].forEach(async (document) => {
                const data = new FormData() // NEW DOCUMENT
                data.append('document', document)
                data.append('info', document.name)
                data.append('type', type)

                await this.setRequest({
                    url: `${requestName}/${k}/documents`,
                    method: 'POST',
                    translation: translationName,
                    body: data,
                })
            })
        })
    }
}
